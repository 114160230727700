<template>
  <b-container class="bg-primary" fluid>
    <b-col class="d-flex flex-column justify-content-center mx-auto vh-100" cols="12" md="6" xl="4">
      <img alt="DW Aligners" class="h3 mx-auto mb-4 text-light w-75 w-md-50" src="../../assets/logo-white.png" />
      <b-card class="p-2 w-100">
        <router-view />
      </b-card>
    </b-col>
  </b-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
