<template>
  <div>
    <p class="h3 text-secondary">Sorry, you can't access this area.</p>
    <p class="text-primary mb-0">If you think this screen has been shown to you in error, please contact your line manager.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
