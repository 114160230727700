<template>
  <b-form @submit.prevent="submit({ email, password })">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Email Address" :invalid-feedback="validationInvalidFeedback(loginErrors, 'email')">
          <b-form-input class="text-monospace" :disabled="isLoggingIn" :state="validationState(loginErrors, 'email')" type="email" v-model="email"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Password" :invalid-feedback="validationInvalidFeedback(loginErrors, 'password')">
          <b-form-input class="text-monospace" :disabled="isLoggingIn" :state="validationState(loginErrors, 'password')" type="password" v-model="password"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" offset-md="8">
        <b-button block type="submit" variant="secondary"><b-spinner small v-if="isLoggingIn"></b-spinner><span v-if="!isLoggingIn">Log In</span></b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      email: '',
      password: ''
    }
  },
  mixins: [validation],
  computed: {
    ...mapGetters('authentication', ['isLoggingIn', 'loginErrors'])
  },
  methods: {
    ...mapActions('authentication', ['login']),
    submit ({ email, password }) {
      this.login({ email, password }).then(response => {
        this.$router.push({ path: this.$route.query.next || '/' })
      })
    }
  }
}
</script>

<style>

</style>
