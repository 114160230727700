<template>
  <b-form @submit.prevent="submit({ email, password })">
    <b-row>
      <b-col cols="12">
        <transition name="fade">
          <p class="text-center"><b-spinner variant="secondary" v-if="isLoggingOut"></b-spinner></p>
          <p class="text-center"><b-icon class="display-1 text-success" icon="check-circle-fill" v-if="!isLoggingOut && !logoutError"></b-icon></p>
          <p class="text-center"><b-icon class="display-1 text-warning" icon="exclamation-triangle-fill" v-if="!isLoggingOut && logoutError"></b-icon></p>
          <p class="mb-0 text-center" v-if="!isLoggingOut && logoutError">We had some problems logging you out.</p>
        </transition>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  mounted () {
    this.logout().then(response => {
      this.$router.push({ name: 'auth.login', query: this.$route.query })
    })
  },
  computed: {
    ...mapGetters('authentication', ['isLoggingOut', 'logoutError'])
  },
  methods: {
    ...mapActions('authentication', ['logout'])
  }
}
</script>

<style>

</style>
